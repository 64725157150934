import { FC } from 'react'
import { Layout } from 'src/components/site/Layout'
import { Section } from 'src/components/parts/Section'
import { Container } from 'src/components/parts/Container'
import { Paragraph } from 'src/components/parts/Paragraph'
import { Breadcrumb } from 'src/components/groups/Breadcrumb'
import { SectionHeading } from 'src/components/parts/SectionHeading'
import { PageHeading } from 'src/components/parts/PageHeading'
import { Text } from 'src/components/parts/Text'
import { TextLink } from 'src/components/parts/TextLink'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

const NotFoundPage: FC = () => {
  const breadcrumbItems = [
    {
      title: 'HOME',
      path: '/',
    },
    {
      title: '404 NOT FOUND!',
      path: '/404/',
    },
  ]

  return (
    <Layout
      title="404 NOT FOUND!"
      description="ページが見つかりませんでした。"
      noindex
      ldType="WebPage"
    >
      <Section>
        <Container>
          <PageHeading heading="404 NOT FOUND!" />
        </Container>
      </Section>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Section>
        <Container small>
          <SectionHeading heading="無効なURL" />
          <Paragraph>
            <Text text="お手数ですが、HOMEに戻って再度お試し下さい。" />
          </Paragraph>
          <Paragraph>
            <TextLink to="/" text="HOMEへ戻る" css={{ marginRight: 'auto' }} />
          </Paragraph>
        </Container>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
